import React, { useEffect, useState } from 'react'
import UploadImgs from '../AllFinishingApps/ImageUploadComp'
import ImageUploader from '../../../../components/ImageUploader'
import { EgButton } from '../../../Shared/Shared'
import GModal from '../../../../components/GModal'
import InputCustom from '../../../../components/InputType'
import { FINISHING_PROGRESS, FINISHING_PROGRESS_IMG, FINISHING_REQUEST } from '../../../../Service/models/Finishing'
import { SendIcon } from '../../../../components/Icons/Icons'
import { GetAllFinishingRequest, SaveFinishProgress } from '../../../../Service/Apis'
import { GetErrorAxios, HavePermission, PERMISSIONS } from '../../../../Globals'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../../redux/store'
import { setLoadingFalse, setLoadingTrue } from '../../../../redux/slices/LoadingSlice'
import { useSearchParams } from 'react-router-dom'
import { UsersState } from '../../../../redux/slices/UserSlice'

type FinishRequestProgress = {
    finishRequest: FINISHING_REQUEST
}
const FinishingProgress = (Props: FinishRequestProgress) => {
    const { finishRequest } = Props
    const [OpenModal, setOpenModal] = useState<boolean>(false)
    const dispatch = useDispatch<AppDispatch>()
    const [searchParams] = useSearchParams();
    // const [finishProgress, setFinishProgress] = useState<FINISHING_PROGRESS[]>([])
    const idValue = searchParams.get("id")
    const UsersData = useSelector(UsersState);

    return (
        <>
            <div className='w-[25%]  overflow-hidden'>
                <h2 className='truncate'>اخر تحديثات التشطيب</h2>
                <hr className='my-2 border-2 border-[#e7c498]' />
            </div>
            <div className='w-full flex flex-col'>
                {/* <p className='text-red-600 font-bold my-8'>*بإمكانك سحب او ادخال عده صور في نفس المكان</p> */}
                {/* <ImageUploader multiImage onImageUpload={(data) => {

                }} /> */}

            </div>
            {UsersData.currentUser.PERMISSIONS && HavePermission(PERMISSIONS.SAVE_FINISHING_PROGRESS, UsersData.currentUser.PERMISSIONS) && (
                <div className='w-36 flex'>
                    <EgButton HandleClick={() => {
                        setOpenModal(true)
                    }}>
                        <h5> <span className='text-2xl'> +</span> اضافه تحديث</h5>
                    </EgButton>
                    <GModal OpenModal={OpenModal} onCloseModal={() => { setOpenModal(false) }}>
                        <UploadProgress FINISHING_REQ_ID={Props.finishRequest.ID??0} />
                    </GModal>
                </div>
            )}
            <div className='w-full flex flex-col mt-8'>
                {Props.finishRequest.FINISHING_PROGRESS?.map(item => {
                    return <div key={item.ID}>
                        <div className='p-2 rounded-md bg-white shadow-lg'>
                            <h3 className='text-center font-bold mb-2'>{item.COMMENT}</h3>
                            <div className='flex flex-row gap-2'>
                                {item.FINISHING_PROGRESS_IMG?.map(img => {
                                    return <div key={img.ID}>
                                        <div className='w-72  p-2 rounded-md bg-white shadow-lg'>
                                        <a href= {img.URL} target='_blank'> <img src={img.URL} alt={item.ID?.toString()} className='w-full h-72 object-cover' /> </a>
                                        </div>
                                        <div className='flex flex-col gap-2'>
                                            <p className='text-center font-bold mt-2'>{img.COMMENT}</p>
                                        </div>

                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </>
    )
}

export default FinishingProgress



const UploadProgress = ({ FINISHING_REQ_ID }: { FINISHING_REQ_ID: number }) => {
    const [progress, setProgress] = useState<FINISHING_PROGRESS>({ FINISHING_REQ_ID: FINISHING_REQ_ID } as FINISHING_PROGRESS)
    const [images, setImages] = useState<FINISHING_PROGRESS_IMG[]>([])
    const dispatch = useDispatch<AppDispatch>()

    const SaveProgress = async () => {
        let request: FINISHING_PROGRESS = progress
        request.FINISHING_PROGRESS_IMG = images
        request.FINISHING_REQ_ID = FINISHING_REQ_ID

        



        try {
            dispatch(setLoadingTrue())
            await SaveFinishProgress(request)
            dispatch(setLoadingFalse())
            setImages([])
            setProgress({} as FINISHING_PROGRESS)

            window.location.reload()
        } catch (err) {
            GetErrorAxios(err, dispatch)
        }
    }

    return (
        <div className='w-full flex flex-col gap-4'>
            <h3 className='font-bold'>اضافه تحديث</h3>
            <hr className='my-2 border-2 border-[#e7c498]' />
            <div className='w-1/2 flex flex-col'>
                <h5 className='mb-5'>العنوان</h5>
                <InputCustom inputChangedVal={(val) => {
                    let value = val as string
                    setProgress(prev => ({ ...prev, COMMENT: value }))
                }} />
            </div>
            <ImageUploader multiImage={true} onImageUpload={(data) => {
                setImages([])
                let imgsConvert: FINISHING_PROGRESS_IMG[] = data.map(item => ({ DATA_BASE: item.base64, FrontID: item.fileName, FINISHING_REQ_ID: FINISHING_REQ_ID })).sort((a, b) => {
                    return a.FrontID!.toString().localeCompare(b.FrontID!.toString());
                })
                
                setImages(imgsConvert)
            }} />
            <div className='flex flex-row gap-2'>
                {images.map(item => {

                    return <div key={item.FrontID}>
                        <div className='w-72  p-2 rounded-md bg-white shadow-lg'>
                            <img src={item.DATA_BASE} alt={item.ID?.toString()} className='w-full h-72 object-cover' />

                            {/* <div className='flex flex-col my-20 gap-2'>
                            {design.DESGINE_COMMENTS?.slice((design.DESGINE_COMMENTS.length - 1) - 3, design.DESGINE_COMMENTS.length).map(comment => {
                                return <div className={`${user === comment.USER_ID ? "self-start bg-blue-100" : "self-end bg-yellow-100"} p-2 rounded-md shadow`}>
                                    <h6 className='text-center'>{comment.STAMP_USER}</h6>
                                    <div>{comment.COMMENT}</div>
                                </div>
                            })}
                        </div> */}

                            <div className='w-full my-8'>
                                <InputCustom DefaultValue={item.COMMENT} inputChangedVal={(val) => {
                                    let value = val as string
                                    let currentImg = images.find(img => img.FrontID === item.FrontID)
                                    if (currentImg) {
                                        currentImg.COMMENT = value
                                    }
                                    let otherImages = images.filter(img => img.FrontID !== item.FrontID)
                                    otherImages.push(currentImg!)
                                    otherImages.sort((a, b) => {
                                        return a.FrontID!.toString().localeCompare(b.FrontID!.toString());
                                    })
                                    setImages(otherImages)
                                }} />
                            </div>
                        </div>
                    </div>
                })}
            </div>
            <div className='w-36'>
                <EgButton HandleClick={() => {
                    SaveProgress()
                }}>
                    <h5>حفظ</h5>
                </EgButton>
            </div>

        </div>
    )
}