import 'devextreme/dist/css/dx.light.css';
import { useDispatch, useSelector } from "react-redux";
import './App.css';
import { AppDispatch } from "./redux/store";


import Login from './screens/Login/Login';

import { useEffect, useRef, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { CssBaseline, Theme, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { CookiesData, getCookies } from './Globals';
import { UserModel } from './Service/models/UserModel/UserModel';
import { CheckedIcon, RemoveLogo, WarnLogo } from './components/Icons/Icons';
import Protect from './components/shared/Protection/Protect';
import { ActionState, DeleteActon, IActionFeed } from './redux/slices/ErrorsSlice';
import { FeedbacFlag } from './redux/slices/Feedback';
import { LoadingState } from './redux/slices/LoadingSlice';
import { SetCurrentUserReducer, UsersState } from './redux/slices/UserSlice';
import Agents from './screens/Agents';
import ContactUs from "./screens/Contactus";
import Actions from './screens/DashBoard/Actions/Actions';
import JoinRequests from './screens/DashBoard/CV';
import Contacts from './screens/DashBoard/Contacts/Contacts';
import DashBoard from './screens/DashBoard/DashBoard';
import DealsActions from './screens/DashBoard/DealsActions';
import EditUsr from './screens/DashBoard/EditUser/EditUsr';
import FeedBack from './screens/DashBoard/FeedBack/FeedBack';
import Founders from './screens/DashBoard/Founders/Founders';
import MeetingRequest from './screens/DashBoard/Meeting';
import Owners from './screens/DashBoard/Owners/Owners';
import ProjectsList from './screens/DashBoard/Projects/projectList';
import Team from './screens/DashBoard/Team/Team';
import Welcome from './screens/DashBoard/welcome';
import Events from './screens/Events';
import Home from './screens/HomePage/Home';
import Loading from './screens/Loading/Loading';
import TRANSRequest from './screens/MOVING_REQ';
import DealPrint from './screens/PrintScreens/DealAndCommission';
import Profile from './screens/Profile';
import Rate from './screens/Rate/Rate';
import { Register } from './screens/Register/Register';
import { SnackbarFeedback } from './screens/Shared/Shared';
import Statistics from './screens/Statistics';
import Terms from './screens/Terms/Terms';
import Join from './screens/join-us';
import Setup from './screens/setup/Setup';
import Jobs from './screens/setup/SetupPannels/Jobs/Jobs';
import ProjectScreen from './screens/setup/SetupPannels/ProjectsData/ProjectScreen';
import Users from './screens/setup/SetupPannels/Users/Users';
import { ColorModeContext, useMode } from './theme';
import Wallet from './screens/Wallet';
import { licenseKey } from './utils/devextreme-license';
import config from 'devextreme/core/config';
import DevComp from './screens/DashBoard/DevelopCompany/index';
import TransActions from './screens/TransActions/index';
import Changepassword from './screens/Login/Changepassword';
import Confirmeemail from './screens/Login/Confirmeemail';
import Messageconf from './screens/Login/Messageconf';
import Proooo from './screens/DashBoard/Projects/proooo';
import AllBoxes from './screens/DashBoard/Boxes';
import Salary from './screens/DashBoard/Salaries/Salary';
import EventsData from './screens/DashBoard/EventsData';
import CitiesComp from './screens/DashBoard/Cities';
import NewRequest from './screens/DashBoard/Finishing/NewRequest';
import FinishingApps from './screens/DashBoard/Finishing/AllFinishingApps';
import Request from './screens/DashBoard/Finishing/AllFinishingApps/Request';
import Finishingitems from './screens/DashBoard/Finishing/FinishingItems/Finishingitems';
import DealsFounders from './screens/DashBoard/DealFounders';
import TeamStatistics from './screens/TeamStatistics';
import FinishingSafe from './screens/DashBoard/finishingSafe';
import BankTransactions from './screens/DashBoard/BankTransactions/BankTransactions';

// Set the DevExtreme license key
config({
  licenseKey
});

const ProtectedDashBoardRoute = ({ user, redirectPath = '/' }: { user: UserModel, redirectPath: any }) => {
  return <DashBoard />;
};

const Empty404 = () => {
  return (
    <div className='w-full h-full'>
      <h1 className='text-5xl' >PAGE NOT FOUND</h1>
    </div>
  )
}

let interval: any = null;
let timeout: any = null;

const ErrorCard = ({ idx, action }: { idx: number, action: IActionFeed }) => {

  const dispatch = useDispatch<AppDispatch>();
  const TimeOutBar = useRef<HTMLDivElement>(null);
  const [reminder, setReminder] = useState<number>(15);
  const timePass = useRef<number>(0);

  timePass.current = 15


  useEffect(() => {
    timeout = window.setTimeout(() => {
      if (TimeOutBar.current) {
        TimeOutBar.current.classList.remove('animeWidth');
        TimeOutBar.current.classList.add('animeWidthEnd');
      }

      interval = setTimeout(() => {
        dispatch(DeleteActon(action.idx!))
        clearTimeout(interval);
        window.clearTimeout(timeout);
      }, 400);
    }, 5000)
  }, [])


  return (
    <div key={idx} ref={TimeOutBar} className={action.status === 'error' ? 'animeWidth flex flex-row  relative bg-red-400 rounded-xl justify-start items-center overflow-hidden' : action.status === 'warning' ? 'animeWidth relative flex flex-row  bg-yellow-500 rounded-2xl justify-start items-center overflow-hidden' : 'animeWidth relative flex flex-row  bg-green-400 rounded-2xl justify-start items-center overflow-hidden opacity-90'}>
      <div className='w-[20px] h-10 mx-5 flex justify-center items-center'>
        <div className='w-3/4 h-3/4 hover:cursor-pointer'
          onClick={() => {
            if (TimeOutBar.current) {
              TimeOutBar.current.classList.remove('animeWidth');
              TimeOutBar.current.classList.add('animeWidthEnd');
            }

            interval = setTimeout(() => {
              dispatch(DeleteActon(action.idx!))
              clearTimeout(interval);
            }, 400);

          }}>
          {action.status === 'error' && (
            <RemoveLogo />
          )}
          {action.status === 'success' && (
            <CheckedIcon />
          )}
          {action.status === 'warning' && (
            <WarnLogo />
          )}
        </div>
      </div>
      <div className='self-stretch p-2 flex justify-start items-center'>
        <p className='text-lg text-white font-thin truncate'>{action.message}</p>
      </div>
      {/* <div ref={TimeOutBar} className='absolute bottom-0 left-0 bg-[#F82020] h-1'>

      </div> */}
    </div>
  )
}

export const ErrorCarrier = () => {


  const Actions = useSelector(ActionState);
  const dispatch = useDispatch<AppDispatch>();
  const [counter, setCounter] = useState<number>(0);
  const counterRef = useRef<number>(0);

  useEffect(() => {

  }, [])



  return (
    <>
      {Actions.length > 0 && (
        <div className='w-full bg-transparent backdrop-blur flex justify-center items-center fixed bottom-0 z-[10000]'>
          <div className='p-5 w-[80%] h-fit flex flex-col-reverse justify-center gap-4'>
            {Actions.map((action, idx) => {
              return (
                <ErrorCard idx={action.idx!} action={action} />
              )
            })}
          </div>
        </div>
      )}

    </>
  )
}
export const dashBoardUrl = "/dashboard"

function App() {

  const [theme, colorMode] = useMode();
  const LoadingFlag = useSelector(LoadingState);
  const feedbackFlag = useSelector(FeedbacFlag);
  const dispatch = useDispatch<AppDispatch>();
  const UsersData = useSelector(UsersState);





  useEffect(() => {
    let user = getCookies(CookiesData.User);

    if (user) {
      let userOBJ = JSON.parse(user) as UserModel;
      dispatch(SetCurrentUserReducer(userOBJ));
    } else {
      dispatch(SetCurrentUserReducer({} as UserModel))
    }
  }, [])

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>

      <ColorModeContext.Provider value={colorMode as { toggleColorMode: () => void; }}>
        <ThemeProvider theme={theme as Theme}>
          <CssBaseline />
          {feedbackFlag && <SnackbarFeedback />}
          {LoadingFlag && <Loading />}
          <ErrorCarrier />
          <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path='/Agents' element={<Agents />}></Route>
              <Route path='/Events' element={<Events />}></Route>
              <Route path='/ContactUs' element={<ContactUs />}></Route>

              <Route path="/login" element={<Login />} />
              <Route path="/Changepassword" element={<Changepassword />} />
              <Route path="/Confirmeemail" element={<Confirmeemail />} />
              <Route path="/Messageconf" element={<Messageconf />} />




              <Route path="/register" element={<Register />} />
              <Route path="/dashboard" element={<ProtectedDashBoardRoute user={UsersData.currentUser} redirectPath={'https://the-team.co/Login/'} />}>
                {/* <Route path="/" element={<ProtectedDashBoardRoute user={UsersData.currentUser} redirectPath={'https://the-team.co/Login/'} />}> */}
                <Route path={dashBoardUrl + "/project"} element={
                  <Protect user={UsersData.currentUser}>
                    <ProjectScreen />
                  </Protect>
                } />
                <Route path={dashBoardUrl + '/setup'} element={<Setup />} />
                <Route path={dashBoardUrl + '/users'} element={<Users />} />
                <Route path={dashBoardUrl + '/jobs'} element={<Jobs />} />
                <Route path={dashBoardUrl + '/Contacts'} element={
                  <Protect user={UsersData.currentUser}>
                    <Contacts />
                  </Protect>
                } />
                <Route path={dashBoardUrl + '/team'} element={

                  <Protect user={UsersData.currentUser}>
                    <Team />
                  </Protect>
                } />
                <Route path={dashBoardUrl + '/EditUser'} element={
                  <Protect user={UsersData.currentUser}>
                    <EditUsr />
                  </Protect>
                } />
                <Route path={dashBoardUrl + '/Actions'} element={
                  <Protect user={UsersData.currentUser}>
                    <Actions />
                  </Protect>
                } />
                <Route path={dashBoardUrl + '/FeedBack'} element={
                  <Protect user={UsersData.currentUser}>
                    <FeedBack />
                  </Protect>
                } />
                <Route path={dashBoardUrl + '/Meetings'} element={
                  <Protect user={UsersData.currentUser}>
                    <MeetingRequest />
                  </Protect>
                } />
                <Route path={dashBoardUrl + '/JoinRequests'} element={
                  <Protect user={UsersData.currentUser}>
                    <JoinRequests />
                  </Protect>
                } />
                <Route path={dashBoardUrl + '/ProjectsList'} element={
                  <Protect user={UsersData.currentUser}>
                    <ProjectsList Granted={true} />
                  </Protect>
                } />
                <Route path={dashBoardUrl + '/Photo'} element={
                  <Protect user={UsersData.currentUser}>
                    <Proooo />
                  </Protect>
                } />
                <Route path={dashBoardUrl + '/AgentProjectsList'} element={
                  <Protect user={UsersData.currentUser}>
                    <ProjectsList Granted={false} />
                  </Protect>
                } />
                <Route path={dashBoardUrl + '/DealsActions'} element={
                  <Protect user={UsersData.currentUser}>
                    <DealsActions />
                  </Protect>
                } />
                <Route path={dashBoardUrl + '/DealFounders'} element={
                  <Protect user={UsersData.currentUser}>
                    <DealsFounders />
                  </Protect>
                } />
                <Route path={dashBoardUrl + '/TransReqs'} element={
                  <Protect user={UsersData.currentUser}>
                    <TRANSRequest />
                  </Protect>
                } />
                <Route path={dashBoardUrl + '/welcome'} element={
                  <Protect user={UsersData.currentUser}>
                    <Welcome />
                  </Protect>
                } />
                <Route path={dashBoardUrl + '/Statistics'} element={
                  <Protect user={UsersData.currentUser}>
                    <Statistics />
                  </Protect>
                } />
                <Route path={dashBoardUrl + '/TeamStatistics'} element={
                  <Protect user={UsersData.currentUser}>
                    <TeamStatistics />
                  </Protect>
                } />
                <Route path={dashBoardUrl + '/Founders'} element={
                  <Protect user={UsersData.currentUser}>
                    <Founders />
                  </Protect>
                } />
                <Route path={dashBoardUrl + '/Owners'} element={
                  <Protect user={UsersData.currentUser}>
                    <Owners />
                  </Protect>
                } />
                <Route path={dashBoardUrl + '/Wallet'} element={
                  <Protect user={UsersData.currentUser}>
                    <Wallet />
                  </Protect>
                } />
                <Route path={dashBoardUrl + '/DevelopCompanies'} element={
                  <Protect user={UsersData.currentUser}>
                    <DevComp />
                  </Protect>
                } />

                <Route path={dashBoardUrl + '/TransActions'} element={
                  <Protect user={UsersData.currentUser}>
                    <TransActions />
                  </Protect>
                } />
                <Route path={dashBoardUrl + '/BankTransActions'} element={
                  <Protect user={UsersData.currentUser}>
                    <BankTransactions />
                  </Protect>
                } />
                <Route path={dashBoardUrl + '/Boxes'} element={
                  <Protect user={UsersData.currentUser}>
                    <AllBoxes />
                  </Protect>
                } />
                <Route path={dashBoardUrl + '/Salary'} element={
                  <Protect user={UsersData.currentUser}>
                    <Salary />
                  </Protect>
                } />
                <Route path={dashBoardUrl + '/EventsData'} element={
                  <Protect user={UsersData.currentUser}>
                    <EventsData />
                  </Protect>
                } />
                <Route path={dashBoardUrl + '/Cities'} element={
                  <Protect user={UsersData.currentUser}>
                    <CitiesComp />
                  </Protect>
                } />
                <Route path={dashBoardUrl + '/NewFinishApplication'} element={
                  <Protect user={UsersData.currentUser}>
                    <NewRequest />
                  </Protect>
                } />
                <Route path={dashBoardUrl + '/FinishingApplications'} element={
                  <Protect user={UsersData.currentUser}>
                    <FinishingApps />
                  </Protect>
                } />
                <Route path={dashBoardUrl + '/Request'} element={
                  <Protect user={UsersData.currentUser}>
                    <Request />
                  </Protect>
                } />
                <Route path={dashBoardUrl + '/FinishingSafe'} element={
                  <Protect user={UsersData.currentUser}>
                    <FinishingSafe />
                  </Protect>
                } />
                <Route path={dashBoardUrl + '/Finishingitems'} element={
                  <Protect user={UsersData.currentUser}>
                    <Finishingitems />
                  </Protect>
                } />
              </Route>
              <Route path="/Rate" element={<Rate />} />
              <Route path="/DealPrint" element={<DealPrint />} />
              <Route path="/Terms" element={<Terms />} />
              <Route path="/join-us" element={<Join />} />
              <Route path="/Profile" element={<Profile />} />
              <Route path="/PostPage" element={<Profile />} />
              <Route path="*" element={<Empty404 />} />
            </Routes>
          </Router>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </LocalizationProvider>
    // 
    // <div className="App">
    //   <Login/>
    //   {/* <h1>{count}</h1>
    //   <button onClick={() => dispatch(increment())}>Deposit</button>
    //   <button onClick={() =>pingHostFromClient()}>Withdraw</button>
    //   <button onClick={() => dispatch(decrement())}>Bankrupt</button> */}
    // </div>
  );
}

export default App;